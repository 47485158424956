/* Metadata badges on video tiles */

.lk-participant-metadata {
  @apply flex flex-row justify-between;
}

.lk-participant-metadata-item {
  @apply min-w-0 rounded-md bg-gray-900/60 backdrop-blur-md;
}

.lk-participant-metadata-item:not(.lk-connection-quality) {
  @apply px-2;
}

.lk-participant-metadata-item.lk-connection-quality {
  @apply shrink-0;
}

.lk-participant-metadata-item svg {
  @apply text-white;
}

.lk-participant-name {
  @apply text-xs font-semibold text-white drop-shadow-md;
}

/* Video tiles */

.lk-participant-tile {
  @apply aspect-video rounded-xl;
}

.lk-participant-tile .lk-participant-placeholder {
  @apply bg-gray-950;
}

.lk-participant-tile:not(
    [data-lk-source='screen_share']
  )[data-lk-speaking='true'] {
  @apply ring ring-blue-500 ring-offset-2 ring-offset-gray-700;
}

.lk-participant-tile .lk-participant-placeholder {
  @apply rounded-xl bg-gray-950;
}

.lk-participant-name {
  @apply truncate;
}

/* Styles for dropdown lists */

.lk-media-device-select {
  @apply font-body p-1.5;
}

.lk-media-device-select li > .lk-button {
  @apply text-13 ease-smooth h-8 rounded-md px-3 py-1.5 font-medium text-gray-900 transition-all duration-300;
}

.lk-media-device-select
  li:not([data-lk-active='true'])
  > .lk-button:not(:disabled):hover {
  @apply bg-gray-400/25;
}

.lk-media-device-select [data-lk-active='true'] > .lk-button {
  @apply bg-gray-400/50 text-gray-900;
}
