@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply antialiased;
}

svg {
  @apply block;
}

path {
  /* Needed to stop strokes from scaling when resizing SVGs (since most of our 
   * icons are stroked) */
  vector-effect: non-scaling-stroke;
}

/* Hide the input type=number's default controls  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
