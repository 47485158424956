/* Video tiles */

/* Removing a global black bg for the video tiles. Awkward! */

.lk-participant-view {
  @apply relative z-[905];
}

.lk-participant-tile .lk-participant-media-video {
  @apply bg-transparent;
}

.lk-participant-view .lk-participant-tile {
  @apply !rounded-md;
}

.lk-participant-view .lk-participant-tile .lk-participant-placeholder {
  @apply bg-gray-950;
}

.lk-participant-view
  .lk-participant-tile:not(
    [data-lk-source='screen_share']
  )[data-lk-speaking='true'] {
  @apply ring ring-blue-500 ring-offset-2 ring-offset-gray-700;
}

/* Hover effect for the participant for video tiles */

.lk-participant-view .lk-participant-tile {
  @apply ease-smooth relative overflow-visible transition-all duration-300;
}

.lk-participant-view .lk-participant-tile .lk-participant-media-video {
  @apply absolute right-0 top-0 h-[60px] w-[60px] rounded-md shadow-none;
}

.lk-participant-view .lk-participant-tile:hover {
  @apply z-10 -translate-x-1/2 translate-y-1/2 scale-[2] shadow-xl;
}

/* Styling for the waiting room modal */

.lk-waiting-room .lk-participant-tile {
  @apply max-h-[30vh];
}

/* Hiding metadata inside participant tiles in the participant view */
.lk-participant-view .lk-participant-tile .lk-participant-metadata {
  @apply hidden;
}
